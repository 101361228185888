import React from "react"
import { useLocalStorageState } from "hooks"

export type EncounterContextType = {
  activeEncounter: {
    adventureId?: string
    encounterId?: string
  } | null
  setActiveEncounter: (
    props: { adventureId?: string; encounterId?: string } | null
  ) => void
}

export const EncounterContext = React.createContext<EncounterContextType>({
  activeEncounter: null,
  setActiveEncounter: () => {},
})

export const EncounterProvider = (props: { children: React.ReactNode }) => {
  const [activeEncounter, setActiveEncounter] = useLocalStorageState<{
    adventureId?: string
    encounterId?: string
  } | null>("encounter/active-encounter", null)

  return (
    <EncounterContext.Provider
      {...props}
      value={{ activeEncounter, setActiveEncounter }}
    />
  )
}

export const useActiveEncounterContext = () =>
  React.useContext(EncounterContext)
