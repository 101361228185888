import App from "next/app"
import Head from "next/head"
import fetch from "node-fetch"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { EncounterProvider } from "context/encounter"
import * as Sentry from "@sentry/browser"

import "focus-visible"

import "../styles/index.css"

import "api/socket"
import { trpc } from "utils/trpc"

// @ts-ignore
if (global.window && process.env.NODE_ENV === "production") {
  console.log("initing sentry")
  Sentry.init({
    dsn: "https://531c9eeaa2574bab8ecc8328143cfd7c@sentry.io/1834251",
  })
}

// @ts-ignore
if (!global.browser) {
  // @ts-ignore
  global.fetch = fetch
}

class AdventurekeepApp extends App {
  render() {
    // @ts-ignore
    const { Component } = this.props

    // @ts-ignore
    const Layout = Component.layout || (({ children }) => children)

    return (
      <>
        <Head>
          <title>Adventurekeep - The 5th Edition Campaign Builder</title>
          {!global?.window?.Stripe && this.props.router.route !== "/" && (
            <script async src="https://js.stripe.com/v3/"></script>
          )}
        </Head>
        <EncounterProvider>
          <Layout>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            <Component {...this.props.pageProps} />
          </Layout>
        </EncounterProvider>
      </>
    )
  }
}

export default trpc.withTRPC(AdventurekeepApp)
