import { splitLink, httpBatchLink } from "@trpc/client"
import { createTRPCNext } from "@trpc/next"
import { inferRouterInputs, inferRouterOutputs } from "@trpc/server"
import { getToken } from "config"
import { compact } from "lodash"
import { wsLink, createWSClient } from "@trpc/client/links/wsLink"
import cuid from "cuid"
import type { AppRouter } from "../../server/src/routers/app"

export const endpointUri =
  process.env.SERVER_URI || "https://adventurekeep-server.herokuapp.com/"
const cleanEndpointUrl = endpointUri.replace(/\/$/, "")
const wsUrl =
  process.env.WEBSOCKET_URI || "wss://adventurekeep-server.herokuapp.com/"

export type RouterInput = inferRouterInputs<AppRouter>
export type RouterOutput = inferRouterOutputs<AppRouter>

const clientId = cuid()

const getHttpBatchLink = () => {
  return httpBatchLink({
    url: `${cleanEndpointUrl}/api/trpc`,
    // needs to be a function so that getToken() is called on every request
    headers: () => ({
      authorization: getToken(),
      "X-Client-ID": clientId,
    }),
  })
}

const getWsLink = () => {
  if (!global.window) {
    return getHttpBatchLink()
  }

  const client = createWSClient({
    url: wsUrl,
  })

  const ws = wsLink<AppRouter>({
    client,
  })

  return ws
}

export const trpc = createTRPCNext<AppRouter>({
  config() {
    return {
      queryClientConfig: {
        defaultOptions: {
          queries: {
            staleTime: Infinity,
          },
        },
      },
      links: compact([
        splitLink({
          condition(op) {
            return op.type === "subscription"
          },
          true: getWsLink(),
          false: getHttpBatchLink(),
        }),
      ]),
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   **/
  ssr: false,
})
