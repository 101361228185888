import io from 'socket.io-client'
import { QueryKey } from '@tanstack/react-query'
import React from 'react'
import { get } from 'lodash'
import { trpc } from '../utils/trpc'

type TrpcContext = ReturnType<typeof trpc.useContext>

// const socket = io(process.env.WEBSOCKET_URI || 'wss://adventurekeep-server.herokuapp.com/')

// const useResourceUpdated = (trpcContext: TrpcContext) => {
//   React.useEffect(() => {
//     const emitter = socket.on(
//       'resource_updated',
//       ({ queryKey, clientId }: { queryKey: QueryKey; clientId: string }) => {
//         console.log({ queryKey, clientId })

//         if (clientId === socket.id) {
//           return
//         }

//         if (typeof queryKey[0] === 'string') {
//           get(trpcContext, queryKey[0]).invalidate()
//         }
//       }
//     )

//     return () => {
//       emitter.off('resource_updated')
//     }
//   }, [])
// }

export const useSocket = (roomId: string[], trpcContext: TrpcContext) => {
  // useResourceUpdated(trpcContext)
  // const joined = React.useRef(false)
  // React.useEffect(() => {
  //   if (!socket.connected) {
  //     socket.on('connect', () => {
  //       if (joined.current) {
  //         return
  //       }
  //       joined.current = true
  //       socket.emit('join', roomId.toString())
  //     })
  //   } else {
  //     if (joined.current) {
  //       return
  //     }
  //     joined.current = true
  //     socket.emit('join', roomId.toString())
  //   }
  // }, [])
  // return () => {
  //   socket.emit('leave', roomId.toString())
  // }
}

const socket = {}

export { socket }
