import * as React from 'react'
import tinykeys, { KeyBindingMap } from 'tinykeys'
import debounce from 'lodash/debounce'

export const useLocalStorageState = <T>(key: string, defaultValue?: T) => {
  let localValue

  try {
    localValue = JSON.parse(window.localStorage.getItem(key) || '')
  } catch (e) {
    localValue = defaultValue
  }

  const [value, setValue] = React.useState<T>(localValue)

  const handleSetValue = (value: T) => {
    if (!process.browser) {
      return
    }

    window.localStorage.setItem(key, value ? JSON.stringify(value) : 'null')
    setValue(value)
  }

  return [value, handleSetValue] as const
}

export const useListState = <T extends {}>(initialState: T[]) => {
  const [list, setList] = React.useState(initialState)

  const removeItemAtIndex = (index: number) => setList(list.filter((item, i) => i !== index))

  const setItemAtIndex = (index: number, item: T) => {
    const copiedList = [...list]
    copiedList[index] = item

    setList(copiedList)
  }

  const addItem = (item: T) => {
    setList(list.concat(item))
  }

  return { list, addItem, removeItemAtIndex, setList, setItemAtIndex }
}

export function useOnClickOutside(handler: (event: MouseEvent) => void) {
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as any)) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener as any)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener as any)
    }
  }, [ref, handler])

  return ref
}

const loadedImages: Record<string, { height: number; width: number }> = {}

export const useImageLoad = (src: string | undefined) => {
  const [loaded, setLoaded] = React.useState(!!src && !!loadedImages[src])
  const [imageInfo, setImageInfo] = React.useState<{ height: number; width: number } | undefined>(
    src ? loadedImages[src] : undefined
  )

  React.useEffect(() => {
    if (!src) {
      return
    }

    setLoaded(!!loadedImages[src])
    setImageInfo(undefined)

    const img = new Image()
    img.src = src
    img.onload = (e) => {
      loadedImages[src] = { height: img.height, width: img.width }

      setImageInfo({ height: img.height, width: img.width })
      setLoaded(true)
    }
  }, [src])

  return { loaded, image: imageInfo }
}

export const useTimerState = <T extends any>(initialValue?: T, durationMs: number = 1000) => {
  const timer = React.useRef<NodeJS.Timeout>()
  const [state, setState] = React.useState(initialValue)

  React.useEffect(() => {
    if (state) {
      timer.current = setTimeout(() => {
        setState(initialValue)
      }, durationMs)
    }

    return () => {
      if (timer.current) {
        window.clearTimeout(timer.current)
      }
    }
  }, [state])

  const clearTimer = () => {
    if (timer.current) {
      window.clearTimeout(timer.current)
    }
  }

  return [state, setState, clearTimer] as const
}

export const useScrollListener = (querySelector: string, listener: () => void) => {
  React.useEffect(() => {
    document.querySelector(querySelector)?.addEventListener('scroll', listener)

    return () => {
      document.querySelector(querySelector)?.removeEventListener('scroll', listener)
    }
  }, [listener])
}

export const useAsyncState = (promiseFn: () => Promise<any>) => {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  const execute = async () => {
    try {
      setError(false)
      setLoading(true)

      await promiseFn()
    } catch (e: any) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  return [execute, { loading, error }] as const
}

export const useMedia = (mediaQuery: string, defaultValue: boolean) => {
  const [value, setValue] = React.useState<boolean>(defaultValue)

  React.useEffect(() => {
    const media = window.matchMedia(mediaQuery)

    media.addListener(() => {
      setValue(media.matches)
    })

    setValue(media.matches)
  }, [mediaQuery])

  return value
}

export const useDebouncedState = <T extends any>(initialValue: T, onChange: (value: T) => void) => {
  const [state, setState] = React.useState(initialValue)

  React.useEffect(() => setState(initialValue), [initialValue])

  const debouncedOnChange = React.useCallback(debounce(onChange, 500), [onChange])

  const handleChange = (value: T) => {
    setState(value)

    debouncedOnChange(value)
  }

  return [state, handleChange] as const
}

export const useDelayedState = <T extends any>(initialValue: T, debounceMs = 250) => {
  const [state, setState] = React.useState<T>(initialValue)

  const handleChange = React.useCallback(
    debounce((value: T) => {
      setState(value)
    }, debounceMs),

    []
  )

  const immediateSetState = (value: T) => {
    setState(value)
    handleChange.cancel()
  }

  return [state, handleChange, immediateSetState] as const
}

export const useMetaKeyListener = (key: string, callback: (e: KeyboardEvent) => void) => {
  const isCtrlPressedRef = React.useRef(false)
  const isMac = global.window?.navigator.userAgent.includes('Mac')

  React.useEffect(() => {
    const keyDownListener = (e: KeyboardEvent) => {
      if (e.key === 'Control') {
        isCtrlPressedRef.current = true
      }

      if ((e.metaKey || (isCtrlPressedRef.current && !isMac)) && e.key === key) {
        e.preventDefault()
        // prevent editor from getting focus after modal is closed; which causes crazy issues
        ;(document.activeElement as HTMLElement)?.blur()
        callback(e)
      }
    }

    const keyUpListener = (e: KeyboardEvent) => {
      if (e.key === 'Control') {
        isCtrlPressedRef.current = false
      }
    }

    document.addEventListener('keydown', keyDownListener)
    document.addEventListener('keyup', keyUpListener)

    return () => {
      document.removeEventListener('keydown', keyDownListener)
      document.removeEventListener('keyup', keyUpListener)
    }
  })
}

export function useKeyboardShortcut(keyBindingMap: KeyBindingMap) {
  React.useEffect(() => {
    let unsubscribe = tinykeys(window, keyBindingMap)

    return () => {
      unsubscribe()
    }
  })
}

export function useFetch<T>(url: string, options: RequestInit) {
  const [error, setError] = React.useState()
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState<T>()

  React.useEffect(() => {
    ;(async () => {
      setError(undefined)
      setLoading(true)
      setData(undefined)

      try {
        const res = await fetch(url, options)
        const json = await res.json()
        setData(json)
        setLoading(false)
      } catch (e: any) {
        setError(e)
      }
    })()
  }, [url])

  return { error, data, loading }
}

export function usePost<P extends object, D>(url: string, params: P) {
  return useFetch<D>(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
